import { Icon } from '@iconify/react'
import React from 'react'
import { Link } from 'react-router-dom'
import Section from '../Section'
import './partner.scss'

export default function Team({ src, name, designation, snippet, facebookHref, linkedinHref, whatsappHref, variant, bgUrl, imageHref }) {
  return (
    <Section className={`cs-team cs-style1 ${variant ? variant : ''} text-center cs-light_bg`}>
      <Section className="cs-partner_thumb cs-bg" style={{ backgroundImage: `${bgUrl && `url(${bgUrl})`}` }}>
        <a href={imageHref} target="_blank" rel="noopener noreferrer">
          <img src={src} alt="Member" />
        </a>
      </Section>
      <Section className="cs-height_25 cs-height_lg_25" />
      <Section className="cs-member_info">
        <Section tag='h2' className="cs-font_24 cs-font_16_sm cs-m0">{name}</Section>
        <Section className="cs-height_5 cs-height_lg_5" />
        <Section tag='p' className="cs-m0">
          <a href={imageHref} target="_blank" rel="noopener noreferrer">{designation}</a>
        </Section>
        <Section className="cs-height_20 cs-height_lg_20" />
        <Section tag='p' className="cs-m0 cs-p20">{snippet}</Section>
        <Section className="cs-height_20 cs-height_lg_20" />
      </Section>
      {/* <div className='team-float-bottom'> */}


        <Section className="cs-member_social cs-center">
          {/* {facebookHref && (
  <Link to={facebookHref} className="cs-primary_color cs-accent_color_hover">
    <Icon icon="fa6-brands:facebook-f" />
  </Link>
)} */}
          {linkedinHref && (
            <Link to={linkedinHref} className="cs-primary_color cs-accent_color_hover">
              <Icon icon="fa6-brands:linkedin-in" />
            </Link>
          )}
          {/* {whatsappHref && (
  <Link to={whatsappHref} className="cs-primary_color cs-accent_color_hover">
    <Icon icon="fa6-brands:whatsapp" />         
  </Link>
)} */}
        </Section>
      {/* </div> */}

    </Section>
  )
}
